@import "../../node_modules/bootstrap/scss/bootstrap.scss";
@import url("https://fonts.googleapis.com/css2?family=Patrick+Hand&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Barlow:wght@300;400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Special+Elite&display=swap");
// $base-bg-clr: #fff5df;
$base-bg-clr: rgb(255, 255, 255);
// $base-txt-clr: #8a733e;
// $base-txt-clr: rgb(17, 17, 184);
$base-txt-clr: black;
body {
    background-color: $base-bg-clr;
    color: $base-txt-clr;
    font-family: "Barlow", sans-serif;
    // font-family: "Special Elite", cursive;
}

//nav bar stuff
.link-deactivate {
    color: grey;
    opacity: 50%;
}

.picture-item {
    cursor: pointer;
}

.active-pic {
    text-decoration: underline;
    font-weight: 700;
}

.landing-title-flex {
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .title {
        font-size: 7em;
    }
    .subtitle {
        font-size: 2em;
    }
    @media (max-width: 715px) {
        .title {
            font-size: 3em;
        }
        .subtitle {
            font-size: 1em;
        }
    }
}
@media (max-width: 715px) {
    .oleg-nav-bar > * {
        margin: 0.15rem 0;
    }
}
.enter-btn-size {
    width: 15%;
    &:hover {
        transform: scale(1.3);
        transition: transform 750ms ease;
    }
}

.enter-btn {
    font-size: 2em;
    color: white;
    &:hover {
        font-size: 2em;
        color: white;
        cursor: pointer;
    }
}

.work-bio-link-text {
    // font-size: 1.5em;
    font-size: 1.2em;
    font-weight: 500;
    @media (max-width: 550px) {
        font-size: 1.1em;
    }
    @media (max-width: 400px) {
        font-size: 1em;
    }
    color: $base-txt-clr;
    text-decoration: none;
    &:hover {
        color: rgb(97, 97, 97);
    }
}
.link-text {
    font-size: 1.5em;
    color: $base-txt-clr;
    text-decoration: none;
    &:hover {
        color: rgb(97, 97, 97);
    }
}
.link-text-small {
    color: $base-txt-clr;
    &:hover {
        color: rgb(97, 97, 97);
    }
}
.link-text-b {
    font-size: 1em;
    color: $base-txt-clr;
}
.head-link-text {
    font-size: 1.3em;
    color: black;
    font-weight: 700;
    :active {
        color: black;
    }
    :visited {
        color: black;
    }
    :link {
        color: black;
    }
    &:hover {
        color: rgb(97, 97, 97);
    }
}

// Project picture links

// .hover-move:hover {
//     transform: translate3d(0, -10px, 0);
//     transform: scale(1.1);
// }

.picture-link {
    color: white;
    text-decoration: none;
    :hover {
        color: white;
    }
}

// .box-shadow {
//     box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.55);
// }

// Individual project page styles
.proj-wrapper {
    // max-width: 100vw;
}
.proj-heading {
}
.proj-img-wrapper {
    height: 500px;
    width: 650px;
    background-size: cover;
    background-repeat: no-repeat;
}

.font-size {
    font-size: 1.2em;
    font-weight: 500;
    @media (max-width: 550px) {
        font-size: 1.1em;
    }
    @media (max-width: 400px) {
        font-size: 1em;
    }
}

.button-groupings {
    display: flex;
    // justify-content: center;
    // margin-left: 7.5%;
    // margin-right: 25%;
    // margin-top: -1%;
}

.next-btn {
    color: $base-txt-clr;
    text-decoration-color: $base-txt-clr;
    &:hover {
        color: grey;
        text-decoration-color: grey;
    }
    &:active {
        color: grey;
        text-decoration-color: grey;
    }
    &:visited {
        color: grey;
        text-decoration-color: grey;
    }
}

@media (max-width: 500px) {
}
.single-proj {
    margin-right: auto;
}
.picture-wrapper {
    width: 30%;
    @media (max-width: 992px) {
        width: 45%;
    }
    @media (max-width: 400px) {
        width: 100%;
    }
}
.picture-size {
    width: 100%;
    min-height: 8em;
}
.bio-page-section {
    display: flex;
}
.button-flex {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    max-width: 50%;
    margin-top: 1%;
    margin-bottom: 1%;
    flex-wrap: wrap;
    @media (max-width: 1100px) {
        max-width: 85%;
    }
    // @media (max-width: 600px) {
    //     flex-direction: column-reverse;
    // }
}
.carousel-image {
    max-height: 70vh;
    max-width: 80%;
    cursor: pointer;
    @media (max-width: 650px) {
        max-width: 90%;
    }
    @media (max-width: 500px) {
        max-width: 95%;
    }
}

.proj-page-footer {
    font-size: 0.9em;
    text-align: start;
    @media (max-width: 650px) {
        text-align: center;
        font-size: 0.8em;
    }
}

.bio-section-text {
    text-align: justify;
    font-size: 1.1em;
}

.projects-container {
    padding-bottom: 6.5%;
    @media (max-width: 750px) {
        padding-left: .5rem;
    }
}

//admin page
.admin-projects-wrapper {
    width: 100vw;
    display: flex;
    margin-top: 0;
    margin-bottom: auto;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    overflow-y: scroll;
}
.admin-pic {
    height: 200px;
    width: 200px;
}
